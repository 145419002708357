/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react'
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import { BullsSliderProps } from './types'
import Image from '@/components/base/image/Image'
import { functions, useWidth } from '@wap-client/core'
import Icon from '@/components/base/icon'
import Anchor from '@/components/base/anchor'
import Slick from "react-slick"
import { Column, Container, Row } from '@/components/base/gridview'

const Slider: React.FC<BullsSliderProps> = ({ data }) => {
  const width = useWidth()
  const slider = useRef<Slick>(null)
  const [activeSlider, setActiveSlider] = useState(0)

  const handleSlideAfterChange = (current: number) => {
    setActiveSlider(current)
  }
  const options = {
    speed: 1500,
    arrows: false,
    waitForAnimate: false,
    fade: true,
    infinite: true,
  }
  const Next = () => {
    slider.current?.slickNext()
  }
  const Prev = () => {
    slider.current?.slickPrev()
  }
  const GoTo = (index: number) => {
    slider.current?.slickGoTo(index)
  }

  return (
    <section className="slider">
      <div className="sliderNav">
        <div className="buttons">
          <button className='arrow arrow-prev' onClick={Prev}>
            <Icon name="arrow-left" className="arrow" />
          </button>
          {data?.map((item, index) => (
            <button key={index} onClick={() => GoTo(index)} className={functions.classnames('dot', index === activeSlider && 'dot-active')} />
            // <button key={index} onClick={() => GoTo(index)} />
          ))}
          <button className='arrow arrow-next' onClick={Next}>
            <Icon name="arrow-right" className="arrow" />
          </button>
        </div>
      </div>
      <Slick afterChange={handleSlideAfterChange} ref={slider} {...options}>
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            {item.href.href ? (
              <Anchor
                href={
                  item.href && item.href.href && item.href.href.length
                    ? item.href.href
                    : ''
                }
                className="slide"
                key={index}
              >
                <div className="slide-inner">
                  <Image
                    src={width > 700 ? item.image.src : item.mobileimage.src}
                    alt=""
                  />
                </div>
                <div
                  className={`slide-caption ${width < 700 ? 'slide-caption-text-white' : ''
                    }`}
                >
                  <div
                    className="slide-caption-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div
                    className="slide-caption-subtitle"
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                  />
                </div>
                {width > 880 && (
                  <button
                    className="clickScroll"
                    onClick={() => {
                      window.scrollTo({
                        top: 650,
                        behavior: 'smooth',
                      })
                    }}
                  >
                    <Icon name="arrow-downward" size={'large'} />
                  </button>
                )}
              </Anchor>
            ) : (
              <div className="slide" key={index}>
                <div className="slide-inner">
                  <Image
                    src={width > 700 ? item.image.src : item.mobileimage.src}
                    alt=""
                  />
                </div>
                <div
                  className={`slide-caption ${width < 700 ? 'slide-caption-text-white' : ''
                    }`}
                >
                  <div
                    className="slide-caption-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div
                    className="slide-caption-subtitle"
                    dangerouslySetInnerHTML={{ __html: item.subtitle }}
                  />
                </div>
                {width > 880 && (
                  <button
                    className="clickScroll"
                    onClick={() => {
                      window.scrollTo({
                        top: 650,
                        behavior: 'smooth',
                      })
                    }}
                  >
                    <Icon name="arrow-downward" size={'large'} />
                  </button>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </Slick>
    </section>
  )
}

export default Slider
